:root {
  --navbar: #ffffff;
  --navbar-text: #000000;
  --primary: #2b63e1;
  --primary-disable: #2b63e190;
  --primary-link: #2b63e1;
  --primary-background: #fff;
  --secondary: #e6f0f6;
  --org-primary: #ffc700;
  --element-primary: #e1e1e1;
  --element-background: #f9f9fce3;
}

@import "assets/css/customs.scss";
@import "assets/css/flexbox.scss";
@import "assets/css/fonts.scss";
@import "assets/css/reset.scss";
@import "assets/css/grid.scss";
