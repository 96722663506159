$white: #fff;
$card-white: #fff;
$black: #000;
$red: #ff0000;
$lite-grey: #f4f4f4;
$grey: #eeeeee;
$grey-text: #a2b2c1;
$dark-grey: #3f4048;
$new-grey: #71859e;
$points: #183242;
$dark-blue: rgba(11, 37, 86);
$dark-blue10: rgba(56, 125, 194, 0.1);
$blue: #4ba5d7;
$primary: #0b2556;
$primary-hover: rgb(12 48 117);
$secondary: #387dc2;
$secondary-hover: #3574b3;
$accordion-text: #e0ddb8;
$home-navbar: #f1efda;
$title-color: #0f467e;
$sub-title-color: #1d589f;
$bg-grey: #edf2f7;
$gold: #c2bb70;
$navbar-bg: #b4ae74;

$ca-home-navbar: #025069;
$ca-primary: #fbb440;
$ca-primary-link: #31a6b0;
$ca-primary-background: #f4ebd3;
$ca-secondary: #e6f0f6;
$org-primary: #ffc700;

$screen-smallMobile: "576px";
$screen-mobile: "768px";
$screen-tab: "992px";
$screen-desktop: "1200px";
$screen-lg-desktop: "1400px";
$screen-lg1-desktop: "1600px";

$smallMobileSize: 540px;
$mobileSize: 720px;
$tabSize: 960px;
$desktopSize: 1140px;
$desktopLgSize: 1320px;
$desktopLg1Size: 80%;
