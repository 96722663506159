@use "variables";

body {
  font-family: roboto;
}

img {
  max-width: 100%;
  height: auto;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 10px;
}

.logout {
  &:hover {
    color: red;
  }
  &:active {
    background: none;
  }
}

figure {
  margin: 0;
}

.App {
  height: 100dvh;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .svg-tick {
    path {
      fill: white;
    }
  }
  .svg-tick-primary {
    border-radius: 50px;
    background-color: var(--primary);
    width: 25px;
    height: 25px;
    text-align: center;
  }
}

.loading-screen-container {
  padding-top: 16px;
  width: 100%;
  @media screen and (min-width: variables.$screen-mobile) {
    width: variables.$smallMobileSize;
  }
  @media screen and (min-width: variables.$screen-tab) {
    width: variables.$mobileSize;
  }
  .loading-screen {
    padding: 0 20px 20px;
    text-align: center;
    border-bottom: 1px solid var(--element-primary);
    .progress-loader-header {
      font-weight: 700;
      font-size: 15px;
    }
    .progress-loader-sub-header {
      font-family: Source Sans Pro;
      font-size: 12px;
      letter-spacing: 0.24px;
    }
  }
}

.container {
  @media screen and (min-width: variables.$screen-lg1-desktop) {
    max-width: variables.$desktopLg1Size;
  }
}
// .container {
// @media screen and (min-width: variables.$screen-smallMobile) {
//   max-width: variables.$smallMobileSize;
// }
// @media screen and (min-width: variables.$screen-mobile) {
//   max-width: variables.$mobileSize;
// }
// @media screen and (min-width: variables.$screen-tab) {
//   max-width: variables.$tabSize;
// }
// @media screen and (min-width: variables.$screen-desktop) {
//   max-width: variables.$desktopSize;
// }
// @media screen and (min-width: variables.$screen-lg-desktop) {
//   max-width: variables.$desktopLgSize;
// }
// }

.loader-container {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  z-index: 1;
  position: absolute;
}

.app-logo {
  pointer-events: none;
  width: 120px;
  height: 120px;
  align-self: center;
  @media screen and (min-width: variables.$screen-tab) {
    width: 160px;
    height: 160px;
  }
  @media screen and (min-width: variables.$screen-desktop) {
    width: 200px;
    height: 200px;
  }
}

.overlay-content {
  height: -webkit-fill-available;
  width: 100%;
  position: absolute;
  z-index: 1;
  min-height: 150px;
}

.center-content {
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal 500 12px/18px Poppins;
  padding: 0 16px;
  margin: auto;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes bounce-out {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}
.react-select-type {
  .react-select__control {
    border-radius: 16px;
    padding: 4px 12px;
    min-height: 48px;
    min-width: 240px;
    margin-right: 16px;
  }
}

.react-select {
  .react-select__control {
    border-radius: 16px;
    padding: 4px 12px;
    min-height: 48px;
    min-width: 240px;
    .react-select__indicators {
      svg {
        color: #555;
      }
      :hover {
        svg {
          cursor: pointer;
          color: #000;
        }
      }
    }
    .react-select__indicator-separator {
      background-color: #555;
    }
  }
}

.icon16 {
  display: block;
  height: 16px;
  width: 16px;
}

.icon18 {
  display: block;
  height: 18px;
  width: 18px;
}

.icon20 {
  display: block;
  height: 20px;
  width: 20px;
}

.icon24 {
  display: block;
  height: 24px;
  width: 24px;
}

.addNew-icon {
  background-image: url("../images/addNew.svg");
  background-size: 100% 100%;
}

.info-icon {
  background-image: url("../images/info.svg");
  background-size: 100% 100%;
}

.msg-icon {
  background-image: url("../images/msg.svg");
  background-size: 100% 100%;
}

.phone-icon {
  background-image: url("../images/phone.svg");
  background-size: 100% 100%;
}

.qr-code-icon {
  background-image: url("../images/qr-code.svg");
  background-size: 100% 100%;
}

.link-icon {
  background-image: url("../images/link.svg");
  background-size: 100% 100%;
}

.hour-glass-icon {
  background-image: url("../images/hour-glass.svg");
  background-size: 100% 100%;
}

.green-check-icon {
  background-image: url("../images/green-check.svg");
  background-size: 100% 100%;
}

.red-bin-icon {
  background-image: url("../images/Delete.svg");
  background-size: 100% 100%;
}

.share-icon {
  background-image: url("../images/share.svg");
  background-size: 100% 100%;
}

.star-icon {
  background-image: url("../images/star.svg");
  background-size: 100% 100%;
}

.star-icon-24 {
  background: url("../images/sprite-image.svg") -24px -65px;
  height: 24px;
  width: 24px;
}

.star-value {
  display: flex;
  gap: 8px;
  align-items: center;
}

.star-icon-16 {
  display: block;
  background-image: url("../images/sprite-image.svg");
  background-position: -26px -67px;
  height: 16px;
  width: 16px;
}

.pointer {
  cursor: pointer;
}

.no-data {
  color: #8a8a8a !important;
}

.point-history {
  padding: 12px 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .value {
    color: #d0ebf1 !important;
  }
  .status {
    color: #d0ebf1 !important;
  }
}

.navbar-container {
  background: variables.$white;
  box-shadow: 0px 1px 0.5px 0px rgba(38, 79, 126, 0.33);
  padding: 0.5rem;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  @media screen and (min-width: variables.$screen-mobile) {
    padding: 0.5rem 1rem;
  }
  @media screen and (min-width: variables.$screen-tab) {
    padding: 0.5rem 1.5rem;
  }
  @media screen and (min-width: variables.$screen-desktop) {
    padding: 0.5rem 4rem;
    max-width: 100%;
  }
  @media screen and (min-width: variables.$screen-lg-desktop) {
    padding: 0.5rem 6rem;
  }
  .user-profile-star {
    display: flex;
    align-items: center;
    padding-left: 0;
    @media screen and (min-width: variables.$screen-tab) {
      padding-left: 8px;
    }
    @media screen and (min-width: variables.$screen-desktop) {
      padding-left: 24px;
    }
    .point-info {
      padding: 8px 16px !important;
      border: 1px solid #e9e9e9;
      gap: 12px;
      display: flex;
      background: #fff;
      border-radius: 25px;
      align-items: center;
      font: normal normal 600 12px/13px Poppins;
      letter-spacing: 0px;
      &:hover {
        background: #e9e4e4;
      }
    }
    .dropdown {
      padding-left: 16px;
      .dropdown-toggle {
        padding: 0;
        height: 40px;
        display: flex;
        gap: 4px;
        align-items: center;
        &::after {
          color: black;
          @media screen and (min-width: variables.$screen-smallMobile) {
            color: var(--navbar-text);
          }
        }
      }
      .btn.show {
        border-color: transparent;
      }
      .btn:focus {
        border-color: transparent;
      }
      .navbar-user-icon-container {
        font: normal normal 600 16px/18px Poppins;
        color: var(--navbar-text);
        display: flex;
        align-items: center;
        .navbar-user-icon {
          border-radius: 50%;
          width: 32px;
          height: 32px;
          @media screen and (min-width: variables.$screen-smallMobile) {
            margin-right: 8px;
            flex-direction: column;
            display: flex;
          }
        }
        .user-name {
          display: none;
          @media screen and (min-width: variables.$screen-smallMobile) {
            flex-direction: column;
            display: flex;
          }
          .level {
            text-align: left;
            font: normal normal 400 11px/14px Poppins;
            letter-spacing: 0px;
            color: var(--navbar-text);
          }
        }
      }
    }
  }
  .frnd-user-profile-star {
    display: flex;
    align-items: center;
    padding-left: 0;
    @media screen and (min-width: variables.$screen-tab) {
      display: none;
    }
    .point-info {
      padding: 8px 16px !important;
      border: 1px solid #e9e9e9;
      gap: 12px;
      display: flex;
      background: #fff;
      border-radius: 25px;
      align-items: center;
      font: normal normal 600 12px/12px Poppins;
      letter-spacing: 0px;
      &:hover {
        background: #e9e4e4;
      }
    }
    .dropdown {
      padding-left: 16px;
      .dropdown-toggle {
        padding: 0;
        height: 40px;
        display: flex;
        gap: 4px;
        align-items: center;
      }
      .btn.show {
        border-color: transparent;
      }
      .btn:focus {
        border-color: transparent;
      }
      .navbar-user-icon-container {
        font: normal normal 600 16px/18px Poppins;
        color: var(--navbar-text);
        display: flex;
        align-items: center;
        .navbar-user-icon {
          border-radius: 50%;
          width: 32px;
          height: 32px;
          @media screen and (min-width: variables.$screen-smallMobile) {
            margin-right: 8px;
            flex-direction: column;
            display: flex;
          }
        }
        .user-name {
          display: none;
          @media screen and (min-width: variables.$screen-smallMobile) {
            flex-direction: column;
            display: flex;
          }
          .level {
            text-align: left;
            font: normal normal 400 11px/14px Poppins;
            letter-spacing: 0px;
            color: var(--navbar-text);
          }
        }
      }
    }
  }
  .nav-link {
    text-align: center;
    font: normal normal 500 15px/23px Poppins;
    letter-spacing: 0px;
    color: var(--navbar-text);
    text-decoration: none;
    &:hover {
      color: var(--primary);
    }
  }
  .nav-link.active {
    font-weight: 600;
    color: var(--primary);
  }
}
.ca-navbar {
  background: var(--element-background);
  box-shadow: none;
  height: 88px;
  @media screen and (min-width: variables.$screen-smallMobile) {
    background: var(--navbar);
    box-shadow: 0px 1px 0.5px 0px #e1e1e1;
  }
  .navbar-brand {
    cursor: pointer;
    padding: 0;
    max-width: 80px;
    img {
      height: 48px;
      width: auto;
      padding-right: 16px;
    }
  }
  .navbar-toggler {
    padding: 2px 4px;
    // border: none;
    &:focus {
      box-shadow: 0 0 0 1px;
    }
    .navbar-toggler-icon {
      // background-image: url("../../assets/images/ca-mobile-menu.svg");
      background-size: 70%;
    }
  }
}

.ca-navbar-mobile {
  background: var(--navbar);
  box-shadow: 0px 0px 8px 1px #e1e1e1;
  padding: 8px;
  @media screen and (min-width: variables.$screen-smallMobile) {
    padding: 12px;
    background: var(--navbar);
  }
  @media screen and (min-width: variables.$screen-tab) {
    display: none;
  }
  .ms-auto {
    width: 100%;
    gap: 1.5rem;
    padding: 0;
    justify-content: center;
    @media screen and (min-width: variables.$screen-smallMobile) {
      gap: 3rem;
    }
  }
  .nav-link {
    border-radius: 50%;
    transition: background-color 0.3s ease;
    padding: 12px;
    justify-items: center;
    width: 48px;
    @media screen and (min-width: variables.$screen-mobile) {
      width: 52px;
    }

    .mobile-icons {
      height: 1.5rem;
      width: 1.5rem;
      transition: 0.15s ease;
      @media screen and (min-width: variables.$screen-mobile) {
        height: 1.75rem;
        width: 1.75rem;
      }
    }
    &:hover {
      background-color: var(--primary);
      svg {
        path {
          fill: white;
        }
      }
    }
  }
  .nav-link.active {
    background-color: var(--primary);
    svg {
      path {
        fill: white;
      }
    }
  }
}

.points-16 {
  display: flex;
  gap: 8px;
  .points-16-text {
    font: normal normal 600 12px/18px Poppins;
    letter-spacing: 0px;
    color: #000000;
    align-items: baseline;
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(11, 37, 86, 0.5);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border-radius: 5px;
  background: rgba(11, 37, 86, 0.7);
}

.modal-fullscreen-md-down {
  .modal-content {
    height: auto;
    .modal-header {
      border-bottom: none;
    }
  }
}
.card-container {
  overflow-y: auto;
  scroll-behavior: smooth;
  padding: 16px;
  align-self: center;
  width: 100%;
  @media screen and (min-width: variables.$screen-mobile) {
    width: variables.$mobileSize;
  }
  .card {
    margin-bottom: 16px;
    box-shadow: 0px 1.5px 0px 0px variables.$navbar-bg;
    .card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 8px;
      justify-content: space-between;
      .text-container {
        width: 100%;
        .card-title {
          color: variables.$sub-title-color;
          font-size: 18px;
          font-weight: 700;
          line-height: 20px;
        }
      }
      button {
        border-radius: 4px;
        width: 100%;
        span {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
}
.card-container::-webkit-scrollbar-thumb {
  background: #a2bccc50;
}
.card-container::-webkit-scrollbar-thumb:hover {
  background: #a2bccc50;
}

.btn-close {
  --bs-btn-close-bg: url("../images/close.svg");
  background-size: 90% 100%;
}

.btn {
  height: 46px;
  text-align: center;
  font: normal normal 600 15px/23px Poppins;
  border-radius: 8px;
  letter-spacing: 0.9px;
  &:disabled {
    cursor: not-allowed;
  }
}

.btn-link {
  text-decoration: none;
  height: auto;
  color: var(--primary);
  font: normal normal 600 15px/23px Poppins;
  height: 46px;
  &:hover {
    color: var(--primary);
  }
}

.btn-light {
  border-color: variables.$dark-grey;
}

.btn-outline-ca-secondary {
  color: var(--primary);
  border-color: var(--primary);
  background-color: variables.$white;
  --bs-btn-border-color: var(--secondary);
  --bs-btn-hover-border-color: var(--secondary);
  &:hover {
    color: var(--primary);
    border-color: var(--primary);
    background-color: variables.$white;
    --bs-btn-bg: var(--secondary);
    --bs-btn-hover-bg: var(--secondary);
    --bs-btn-active-bg: var(--secondary);
  }
  &:active {
    color: var(--primary) !important;
    border-color: var(--primary);
    background-color: variables.$white;
  }
}

.btn-ca-secondary {
  color: var(--primary);
  box-shadow: 0px 1px 0.5px 0px rgba(17, 67, 97, 0.33);
  --bs-btn-bg: var(--secondary);
  --bs-btn-border-color: var(--secondary);
  --bs-btn-hover-bg: var(--secondary);
  --bs-btn-hover-border-color: var(--secondary);
  --bs-btn-active-bg: var(--secondary);
  &:hover {
    color: var(--primary);
  }
  &:active {
    color: var(--primary);
  }
}

.btn-ca-primary {
  color: variables.$white;
  --bs-btn-bg: var(--primary);
  --bs-btn-border-color: var(--primary);
  --bs-btn-hover-bg: var(--primary);
  --bs-btn-hover-border-color: var(--primary);
  --bs-btn-active-bg: var(--primary);
  box-shadow: 0px 1px 0.5px 0px rgba(17, 67, 97, 0.33);
  &:hover {
    color: variables.$white;
  }
  &:active {
    color: variables.$white !important;
  }
  &:disabled {
    background-color: var(--primary-disable);
    color: variables.$white;
    border-color: var(--primary-disable);
  }
}

.btn-outline-ca-primary {
  --bs-btn-color: var(--primary);
  --bs-btn-border-color: var(--primary);

  --bs-btn-active-color: var(--primary);
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: var(--primary);

  --bs-btn-hover-color: white;
  --bs-btn-hover-bg: var(--primary);
  --bs-btn-hover-border-color: var(--primary);

  --bs-btn-disabled-color: var(--primary-disable);
  --bs-btn-disabled-border-color: var(--primary-disable);
  .svg-fill {
    path {
      fill: var(--primary);
    }
  }
  .svg-stroke {
    path {
      stroke: var(--primary);
    }
  }
  &:hover {
    .svg-fill {
      path {
        fill: white;
      }
    }
    .svg-stroke {
      path {
        stroke: white;
      }
    }
  }
}

.btn-center {
  text-align: center;
  width: 100%;
}

.btn-with-icon {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  svg {
    width: 24px;
    height: 20px;
    path {
      fill: white;
    }
  }
}

.btn-container50 {
  .btn:nth-child(odd) {
    width: calc(50% - 8px);
    margin-right: 8px;
  }
  .btn:nth-child(even) {
    width: calc(50% - 8px);
    margin-left: 8px;
  }
}

.rounded-btn {
  border-radius: 24px;
}

.link {
  text-decoration: none;
  color: var(--primary-link);
  font-weight: 500;
  cursor: pointer;
}

.progress-bar {
  height: 10px;
  width: 100%;
  background-color: whitesmoke;
  border-radius: 40px;
  margin-top: 50px;
  overflow: visible;
  .bar {
    height: 100%;
    background-color: rgb(75, 165, 215);
    border-radius: 40px;
    text-align: right;
    transition: all 0.5s ease 0s;
    .tooltip-ele {
      top: -27px;
      left: -10px;
      display: block;
      position: relative;
      float: right;
      color: variables.$white;
      font-family: Source Sans Pro;
      font-style: normal;
      line-height: 16px;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.4;
      border-radius: 4px;
      transition: 0.15s ease;
      .text {
        position: absolute;
        top: -4px;
        left: -10px;
        background: variables.$secondary;
        padding: 4px;
        width: 40px;
        text-align: center;
        text-align: -webkit-center;
        border-radius: 6px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: variables.$white;
        font-family: Source Sans Pro;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        .arrow {
          position: absolute;
          bottom: -6px;
          z-index: 1;
          right: 15px;
        }
      }
      .circle-svg {
        position: absolute;
        top: 22px;
      }
    }
  }
}

.login-screen {
  height: 100%;
  padding-top: 16px;
  background-color: white;
  .form-container {
    padding: 16px;
    .btn {
      width: 100%;
    }
  }
}

.custom-card > .card-header {
  background-color: var(--primary);
}

.list {
  overflow-y: auto;
  scroll-behavior: smooth;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  @media screen and (min-width: variables.$screen-smallMobile) {
    width: variables.$smallMobileSize;
  }
  @media screen and (min-width: variables.$screen-mobile) {
    width: variables.$mobileSize;
  }
  .accordion {
    .card {
      color: variables.$white;
      background-color: var(--navbar);
      font-family: "Roboto Condensed";
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
      .card-header {
        display: flex;
        justify-content: space-between;
        padding: 18px;
        border-radius: 8px;
        .accordion-header {
          font-weight: 600;
          color: variables.$accordion-text;
          line-height: 16px;
          display: flex;
          gap: 16px;
          align-items: center;
          cursor: pointer;
          .click-arrow {
            height: 16px;
            width: 16px;
            background-image: url("../../assets/images/arrow1.svg");
            background-size: 100% 100%;
            border: none;
            transition: 0.15s ease;
          }
          .click-arrow-down {
            transform: rotate(90deg);
            transition: 0.15s ease;
          }
        }
      }
      .card-body {
        padding-top: 0;
        .contact-list {
          &:after {
            display: block;
            content: "";
            border-bottom: 1px solid #f1efda80;
            padding: 4px 0px;
          }
          .contact-name {
            padding-top: 18px;
            display: flex;
            justify-content: space-between;
            font: 600 18px/111% "Roboto Condensed", sans-serif;
            .contact-icons {
              display: flex;
              gap: 12px;
              .icon20 {
                background-color: white;
                border-radius: 50%;
                padding: 2px;
                height: 32px;
                width: 32px;
                background-size: 50% 100%;
                background-repeat: no-repeat;
                background-position-x: center;
                background-position-y: center;
              }
              .green-check-icon {
                background-color: white;
                border-radius: 50%;
                padding: 2px;
                height: 32px;
                width: 32px;
              }
            }
          }
          .contact-from {
            color: #d0ebf1;
            margin-top: 4px;
            letter-spacing: 0.28px;
            font: 400 14px/15px Source Sans Pro;
          }
        }
        .faded {
          color: #85a2c8;
        }
      }
    }
  }
}
.list::-webkit-scrollbar-thumb {
  background: #a2bccc50;
}
.list::-webkit-scrollbar-thumb:hover {
  background: #a2bccc50;
}

.challenge-contact-list-card {
}

.expended-options {
  padding: 16px;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 4px #0000000d;
  border-radius: 8px;
  margin-bottom: 16px;
  &:last-child {
    border: none;
    &::after {
      border: none;
    }
  }
  .contact-detail {
    display: flex;
    gap: 16px;
    .navbar-user-icon-contact {
      margin-top: 4px;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      @media screen and (min-width: variables.$screen-mobile) {
        width: 48px;
        height: 48px;
      }
      @media screen and (min-width: variables.$screen-tab) {
        width: 56px;
        height: 56px;
      }
    }
    .navbar-user-icon {
      margin-top: 4px;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      @media screen and (min-width: variables.$screen-mobile) {
        width: 48px;
        height: 48px;
      }
    }
    .contact-detail-section {
      flex: 1;
      text-align: start;
      .action-btn {
        min-width: 52px;
        text-align: right;
      }
    }
  }
  svg {
    cursor: pointer;
  }
  .close-icon {
    path {
      stroke: #000;
    }
    &:hover {
      path {
        stroke: variables.$red;
      }
    }
  }
  .add-new {
    path {
      fill: black;
    }
    &:hover {
      path {
        fill: var(--primary-link);
      }
    }
  }
  .contact-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: normal normal 500 16px/25px Poppins;
    letter-spacing: 0px;
    color: #000000;
    .contact-icons {
      display: flex;
      gap: 12px;
      .icon20 {
        background-size: 75% 100%;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
      }
      .green-check-icon {
        background-color: white;
        border-radius: 50%;
        padding: 2px;
        height: 32px;
        width: 32px;
        border: 1px solid white;
      }
    }
  }
  .contact-detail-link {
    color: var(--navbar);
    cursor: pointer;
    &:hover {
      color: var(--primary-link);
    }
  }
  .other-details {
    font: normal normal 400 13px/20px Poppins;
    letter-spacing: 0.13px;
    color: #9fa7bb;
  }
  .voting-history {
    font: normal normal 500 13px/20px Poppins;
    letter-spacing: 0.13px;
    color: #303238;
  }
  .remove-options {
    margin-top: 8px;
    transition: 10s ease;
  }
  .contact-from {
    font: normal normal normal 13px/20px Poppins;
    letter-spacing: 0.13px;
    color: #9fa7bb;
    a {
      color: #9fa7bb;
    }
  }
  .one-done {
    path {
      fill: white;
      &:nth-child(2) {
        fill: var(--primary-link);
      }
      &:nth-child(3) {
        fill: var(--primary-link);
      }
    }
  }
  .two-done {
    path {
      fill: white;
      &:nth-child(3) {
        fill: var(--primary-link);
      }
    }
  }
  .three-done {
    path {
      fill: white;
    }
  }
  .none-three {
    path {
      fill: var(--primary-link);
    }
  }
}

.expended-options-selection {
  background: #ffffff;
  box-shadow: 0px 0px 4px #0000000d;
  border-radius: 7px;
  border: 2px solid transparent;
  margin: 8px 0;
  cursor: pointer;
  &:hover {
    border: 2px solid var(--primary);
  }
  &::after {
    border-bottom: none;
    padding: 4px 0px;
  }
}

.selected-option {
  border-color: var(--primary);
}

.user-container {
  background: variables.$white;
  width: 100%;
  text-align: center;
  text-align: -webkit-center;
  .container {
    .user-info {
      display: flex;
      justify-content: space-between;
      padding: 16px;
      width: 100%;
      align-items: center;
      .info-container {
        color: variables.$primary;
        font-family: "Roboto Condensed";
        font-style: normal;
        display: flex;
        align-items: center;
        .camera {
          height: 24px;
          width: 24px;
          position: relative;
          right: 24px;
          background: #87a9be;
          border-radius: 50%;
          align-self: end;
          svg {
            height: 12px;
            width: 12px;
          }
        }
        .name {
          text-align: left;
          color: #212936;
          font-family: "Roboto Condensed";
          font-size: 21px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          @media screen and (min-width: variables.$screen-smallMobile) {
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
          }
        }
        .sub-details {
          display: flex;
          gap: 22px;
          .triplers {
            font-size: 12px;
            font-weight: 700;
            line-height: normal;
            span {
              color: variables.$secondary;
              padding-right: 2px;
            }
          }
        }
      }
      .points-container {
        display: flex;
        border-radius: 8px;
        background: var(--white, #fff);
        padding: 8px;
        @media screen and (min-width: variables.$screen-smallMobile) {
          padding: 12px;
        }
        @media screen and (min-width: variables.$screen-mobile) {
          padding: 16px;
        }
        .star {
          padding-right: 8px;
        }
        .points {
          color: variables.$points;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          .score {
            color: variables.$grey-text;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            @media screen and (min-width: variables.$screen-tab) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
.loader-sample {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background: grey;
}

.custom-small-modal {
  .modal-dialog {
    justify-content: center;
    .container {
      width: 100%;
    }
    .modal-content {
      border-radius: 20px;
      width: 100%;
      max-width: variables.$smallMobileSize;
      margin: 16px;
      @media screen and (min-width: variables.$screen-smallMobile) {
        margin: 24px;
      }
      @media screen and (min-width: variables.$screen-mobile) {
        margin: 0;
      }
      .modal-header {
        font: normal normal 600 19px/26px Poppins;
        letter-spacing: 0px;
        color: #000000;
      }
      .confirm-modal-msg {
        height: 100px;
        align-content: center;
        text-align: center;
        font: normal normal 500 19px/26px Poppins;
        letter-spacing: 0px;
        color: #000000;
      }
      .btn-container {
        display: flex;
        gap: 16px;
        justify-content: center;
        border-top: 1px solid #cdcdcd;
        padding-top: 16px;
      }
    }
  }
}

form {
  height: 100%;
  display: flex;
  flex-direction: column;
  .form-container {
    flex: 1;
    .form-control {
      border: 1px solid #707070;
      border-radius: 8px;
      text-align: left;
      font: normal normal 500 15px/32px Poppins;
      letter-spacing: -0.15px;
      height: 52px;
      &:focus {
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
      }
      &::placeholder {
        opacity: 1; /* Firefox */
        color: variables.$grey-text;
      }
    }
    label {
      font: normal normal 500 15px/32px Poppins;
      letter-spacing: -0.15px;
      color: #000000;
      width: 100%;
    }
    .phone-confirm-text {
      font: normal normal 500 13px/32px Poppins;
      @media screen and (min-width: variables.$screen-smallMobile) {
        font: normal normal 500 15px/32px Poppins;
      }
      .grey-text {
        text-align: center;
        letter-spacing: -0.15px;
        color: #a9a9bb;
      }
      .phone-number {
        padding-left: 8px;
      }
    }
    .checkbox-label {
      display: flex;
      align-items: center;
      letter-spacing: 0.15px;
      font: normal normal 400 15px/22px Poppins;
      .form-check-input[type="checkbox"] {
        width: 21px;
        height: 21px;
        border-color: #555;
      }
      .form-check-input:checked[type="checkbox"] {
      }
    }
    .otp-input {
      width: 100%;
      justify-content: space-between;
      gap: 1vw;
      @media screen and (min-width: variables.$screen-smallMobile) {
        gap: 1rem;
        justify-content: center;
      }
      input {
        width: 100%;
        flex: 1;
        height: 57px;
        border: 1px solid #707070;
        border-radius: 8px;
        font: normal normal 500 18px/32px Poppins;
        max-width: 5rem;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    .refer {
      text-align: center;
      text-align: -webkit-center;
      label {
        font-size: 1.125rem;
      }
      .form-control {
        border: none;
        letter-spacing: 4px;
        box-shadow: none;
        color: #193c51;
        font-family: "Roboto Condensed";
        font-size: 32px;
        font-weight: 500;
        text-align: center;
      }
    }
    .resend-container {
      margin-bottom: 16px;
      color: var(--primary);
      font-weight: 600;
      height: 30px;
      text-align: left;
      .pointer {
        svg {
          path {
            fill: var(--primary);
            &:nth-child(3) {
              fill: none;
              stroke: var(--primary);
            }
          }
        }
      }
    }
  }
}

.profile-img {
  border-radius: 50%;
  height: 72px;
  width: 72px;
  @media screen and (min-width: variables.$screen-smallMobile) {
    width: 84px;
    height: 84px;
  }
}

.contact-detail-page {
  .one-done {
    path {
      fill: #85a2c8;
      &:nth-child(2) {
        fill: #c8defc;
      }
      &:nth-child(3) {
        fill: #c8defc;
      }
    }
  }
  .two-done {
    path {
      fill: #85a2c8;
      &:nth-child(3) {
        fill: #c8defc;
      }
    }
  }
  .three-done {
    path {
      fill: #85a2c8;
    }
  }
  .none-three {
    path {
      fill: #c8defc;
    }
  }
}

.not-onmobile-nav {
  display: none;
  @media screen and (min-width: variables.$screen-smallMobile) {
    display: block;
  }
}

.only-mobile-nav {
  display: flex;
  align-items: center;
  padding: 11px 16px;
  height: 56px;
  box-shadow: 0px 1px 1px 0px var(--element-primary);
  @media screen and (min-width: variables.$screen-smallMobile) {
    display: none;
  }
  .back-icon {
    background: url("../images/arrow-left.svg") no-repeat;
    background-position: center;
    height: 32px;
    min-width: 32px;
    cursor: pointer;
    position: absolute;
    border-radius: 50%;
    background-color: var(--element-background);
  }
  .navbar-header-name {
    width: 100%;
    text-align: center;
    font: normal normal 600 18px/23px Poppins;
  }
  .navbar-mobile-only-class {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px 16px 0 40px;
    .point-info {
      cursor: pointer;
      padding: 8px 16px !important;
      border: 1px solid #e9e9e9;
      gap: 12px;
      display: flex;
      background: #fff;
      border-radius: 25px;
      align-items: center;
      font: normal normal 600 12px/12px Poppins;
      letter-spacing: 0px;
      &:hover {
        background: #e9e4e4;
      }
    }
  }
}

.tos-main-container {
  background-color: rgba(255, 255, 255, 1);
  .container {
    padding-bottom: 16px;
  }
  .agreement {
    line-height: 1.3em;
    margin-bottom: 12px;
  }
  .agreement1 {
    line-height: 1.3em;
    margin-bottom: 0px;
  }
  .agreement-ul {
    margin-top: 12px;
  }
  h1 {
    font-weight: 700;
    font-size: 54px;
    color: #000 !important;
    line-height: 72px;
  }
  h2 {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 24px;
    color: #009eff !important;
    line-height: 32px;
  }
}
.toast {
  border: 0;
  --bs-toast-header-border-color: none;
  .toast-header {
    padding: 16px;
    border-radius: 6px;
  }
}

.bottom-modal {
  backdrop-filter: blur(4px) brightness(100%);
  .modal-content {
    border-radius: 20px 20px 0px 0px;
    margin-top: auto;
    @media screen and (min-width: variables.$screen-smallMobile) {
      margin: auto;
      border-radius: 20px;
      width: variables.$smallMobileSize;
    }
    @media screen and (min-width: variables.$screen-mobile) {
      width: variables.$mobileSize;
      border-radius: 20px;
    }
    .modal-header {
      font: normal normal 600 19px/26px Poppins;
      letter-spacing: 0px;
      color: #000000;
      border-bottom: 1px solid #e9e9e9;
    }
    .modal-body {
      padding: 0 24px 24px;
      .bottom-modal-container {
        gap: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .header {
          color: #212936;
          font: normal normal 500 16px/20px Poppins;
          width: 100%;
        }
        .link-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          background: white;
          border-radius: 8px;
          .link-text {
            color: var(--primary-link);
            font: normal normal 400 14px/20px Poppins;
            letter-spacing: 0px;
            overflow-wrap: anywhere;
          }
        }
        .qr-code-container {
          border-radius: 8px;
          background: var(--primary-link);
          display: flex;
          padding: 24px;
          gap: 4px;
          aspect-ratio: 1;
          .qr-code {
            display: flex;
            width: 100%;
            height: 100%;
            max-width: 200px;
            max-height: 200px;
            padding: 16px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background: #fff;
          }
          @media screen and (min-width: variables.$screen-smallMobile) {
            padding: 28px;
            .qr-code {
              padding: 20px;
              max-width: 250px;
              max-height: 250px;
            }
          }
        }
      }
    }
    .bottom-btn-container {
      display: flex;
      gap: 16px;
      justify-content: right;
      margin: 0;
      button {
        font-family: Source Sans Pro;
        width: 120px;
      }
    }
  }
}
.share-challenge-button {
  bottom: 0;
  width: 100%;
  @media screen and (min-width: variables.$screen-tab) {
    position: initial;
    width: 100%;
  }
}

.timer {
  border-radius: 24px;
  border: 1px solid white;
  width: 34px;
  height: 34px;
  align-content: center;
}
.disabled-send-again {
  width: 100%;
  display: flex;
  gap: 32px;
  text-align: center;
  align-items: center;
  color: #828282;
  cursor: not-allowed;
  .timer {
    border-color: rgb(130, 130, 130);
    height: 32px;
    width: 32px;
  }
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.error-img-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  .error-img {
    height: auto;
    width: 80%;
    @media screen and (min-width: variables.$screen-smallMobile) {
      width: 70%;
    }
    @media screen and (min-width: variables.$screen-mobile) {
      width: 60%;
    }
    @media screen and (min-width: variables.$screen-tab) {
      width: variables.$smallMobileSize;
    }
  }
}

.dummy-card {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  .balance-header {
    color: #788b9d;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 8px;
  }
}

.text-with-link {
  display: flex;
  justify-content: center;
  text-align: center;
  font: normal normal 500 16px/32px Poppins;
  letter-spacing: -0.16px;
  gap: 8px;
}

.text-divider {
  margin: 0 auto;
  width: 90%;
  max-width: variables.$mobileSize;
  font: normal normal 600 12px/18px Poppins;
  letter-spacing: 1.44px;
  color: #7d8ca7;
  --text-divider-gap: 1rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  @media screen and (min-width: variables.$screen-smallMobile) {
    width: 75%;
  }
  &::before,
  &::after {
    content: "";
    height: 1px;
    background-color: silver;
    flex-grow: 1;
  }

  &::before {
    margin-right: var(--text-divider-gap);
  }

  &::after {
    margin-left: var(--text-divider-gap);
  }
}

.custom-input::placeholder {
  color: #888;
  font-weight: 400;
}
.noShadow {
  box-shadow: none !important;
}
.contact-tags {
  padding: 2px 4px;
  background-color: rgba(0, 128, 0);
  color: white;
  border-radius: 4px;
  margin-right: 4px;

  font: normal normal normal 13px/20px Poppins;
  letter-spacing: 0.13px;
  color: #ffffff;
}
.red {
  background-color: #c53737;
}

.error {
  font: normal normal 400 15px/30px Poppins;
  letter-spacing: 0px;
  color: #ac3d3d;
}

.bold {
  font-weight: 600;
}

.iphone-msg-temp {
  display: flex;
  gap: 8px;
  justify-content: center;
  padding: 16px;
  @media screen and (min-width: variables.$screen-smallMobile) {
    display: none;
  }
  svg {
    margin-top: 4px;
    height: 24px;
    min-width: 24px;
  }
}

.refer-name-link {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.challenge-header {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  width: 100%;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  justify-content: center;
  .name {
    color: #212936;
    font-family: "Roboto Condensed";
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.option-container {
  transition: 0.2s ease;
  .checkbox-container {
    padding: 16px 32px 0;
    .form-check {
      display: flex;
      gap: 16px;
      margin-bottom: 12px;
      .form-check-input {
        &:checked[type="radio"] {
          background-color: white;
          --bs-form-check-bg-image: url("../images/radio-selected.svg");
        }
        &[type="radio"] {
          width: 21px;
          height: 21px;
          background-color: transparent;
          border-color: #3e4060;
        }
      }
      .form-check-label {
        font: normal normal 500 16px/26px Poppins;
        letter-spacing: 0px;
        color: #000000;
      }
    }
  }
}

.nav-tabs {
  background: var(--element-background);
  border-radius: 10px;
  border: none;
  max-width: variables.$mobileSize;
  margin: 0 12px;
  margin-bottom: 8px;
  justify-content: center;
  padding: 8px;
  @media screen and (min-width: variables.$screen-mobile) {
    margin: 0;
    margin-bottom: 8px;
  }
  .nav-link {
    text-align: center;
    border: none;
    font: normal normal 500 15px/23px Poppins;
    letter-spacing: 0px;
    color: #000000;
    padding: 8px;
  }
  .nav-link.active {
    box-shadow: 0px 0px 4px #0000000d;
    border-radius: 7px;
    font-weight: 600;
  }
  .nav-item.show {
    color: #183242;
    border-bottom: 4px solid #009eff;
  }
}

.phone-number-input {
  display: flex;
  align-items: center;
  position: relative;
  .phone-icon {
    position: absolute;
    left: 16px;
    transform: rotate(90deg);
    path {
      fill: #a4b1cf;
    }
  }
  .star-icon-24 {
    position: absolute;
    left: 16px;
  }
  .input-icon {
    position: absolute;
    left: 16px;
  }
  input {
    padding: 16px;
    padding-left: 48px;
    margin-right: -16px;
    font: normal normal 500 18px/32px Poppins;
    letter-spacing: -0.18px;
    color: #000000;
  }
  .sign-in-btn {
    position: absolute;
    right: 0;
    font: normal normal 500 16px/32px Poppins;
    letter-spacing: -0.16px;
    color: var(--primary);
    path {
      fill: var(--primary);
    }
  }
  .counter-text {
    position: absolute;
    right: 16px;
    font: normal normal 500 14px/32px Poppins;
    letter-spacing: -0.16px;
    color: #a0a7b7;
    display: flex;
    gap: 4px;
    .counter {
      display: block;
      width: 30px;
      color: black;
    }
    @media screen and (min-width: variables.$screen-smallMobile) {
      font: normal normal 500 16px/32px Poppins;
    }
  }
}
